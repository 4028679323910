// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("C:\\Code\\fun-factory-asia-quiz\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\Code\\fun-factory-asia-quiz\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quiz-tsx": () => import("C:\\Code\\fun-factory-asia-quiz\\src\\pages\\quiz.tsx" /* webpackChunkName: "component---src-pages-quiz-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Code\\fun-factory-asia-quiz\\.cache\\data.json")

